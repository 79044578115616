
export const environment = {
    production: true,
  
    keycloakUrl : 'https://sso.dev.iti.gov.br/',
    keycloakRealm : 'cubo',
    keycloakClientId: 'cubo-cliente',

    cadastroApiUrl: '/api/cadastro',
    administrativoApiUrl: '/api/administrativo',
    analiseApiUrl: '/api/analise',
    pdfApiUrl: '/api/pdf',
    
    pdfApiUrlEvoSdk: 'https://dev-cubo.iti.gov.br/api/pdf',
    urlGovBr: 'https://sso.staging.acesso.gov.br',

    ANGULAR_PROXY_HOST_API_ADMINISTRATIVO: 'https://dev-cubo.iti.gov.br',
    ANGULAR_PROXY_HOST_API_ANALISE: 'https://dev-cubo.iti.gov.br',
    ANGULAR_PROXY_HOST_API_CADASTRO: 'https://dev-cubo.iti.gov.br',
    ANGULAR_PROXY_HOST_API_PDF: 'https://dev-cubo.iti.gov.br',
};
